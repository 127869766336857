import React from 'react';
import styled from 'styled-components';
import {
  fontSize,
  fontWeights,
  maxBreakpointQuery,
  minBreakpointQuery,
} from '../styles';
import { Svg } from './ui';
import visaIcon from '../images/visa-icon.inline.svg';
import mastercardIcon from '../images/mastercard-icon.inline.svg';
import maestroIcon from '../images/maestro-icon.inline.svg';
import directDebitIcon from '../images/direct-debit-icon.inline.svg';

const StyledPaymentIcons = styled.div`
  display: flex;
  gap: 10px;

  ${maxBreakpointQuery.small`
    flex-direction: column;
  `}

  ${minBreakpointQuery.small`
    align-items: center;
  `}

  ${maxBreakpointQuery.medium`
    margin-top: 20px;
  `}

  ${maxBreakpointQuery.small`
    margin-top: 15px;
  `}
`;

const StyledText = styled.p`
  ${fontSize(12)};
  font-weight: ${fontWeights.medium};
`;

const StyledIcons = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const StyledIconItem = styled.li``;

const StyledIcon = styled(Svg)`
  height: 40px;
  width: 64px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
`;

const PaymentIcons = () => (
  <StyledPaymentIcons>
    <StyledText>Safe and secure checkout with:</StyledText>
    <StyledIcons>
      <StyledIconItem>
        <StyledIcon image={visaIcon} />
      </StyledIconItem>
      <StyledIconItem>
        <StyledIcon image={mastercardIcon} />
      </StyledIconItem>
      <StyledIconItem>
        <StyledIcon image={maestroIcon} />
      </StyledIconItem>
      <StyledIconItem>
        <StyledIcon image={directDebitIcon} />
      </StyledIconItem>
    </StyledIcons>
  </StyledPaymentIcons>
);

export default PaymentIcons;
