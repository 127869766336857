import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  brandColours,
  brandFonts,
  checkboxStyles,
  fontSize,
  fontWeights,
  minBreakpointQuery,
  sectionMargins,
  sectionPaddings,
  standardColours,
  visuallyHidden,
} from '../styles';
import { Button, Container, SectionHeader, Svg } from './ui';
import PaymentIcons from './PaymentIcons';
import grassBg from '../images/grass-bg.svg';
import giftAidLogo from '../images/gift-aid-logo.inline.svg';

const StyledDonorfyForm = styled.section``;

const StyledInner = styled.form`
  .StripeElement {
    height: 40px;
    padding: 10px 12px;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }
`;

const StyledTop = styled.div`
  ${sectionMargins()}
`;

const StyledTopInner = styled.div`
  display: grid;
  row-gap: 30px;

  ${minBreakpointQuery.medium`
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  `}
`;

const StyledBottom = styled.div`
  background: url('${grassBg}') no-repeat calc(100% + 50px) calc(100% + 50px) /
    200px ${standardColours.lightGrey};
  padding: 34px 0;
  ${sectionPaddings()}
`;

const StyledBottomInner = styled.div`
  display: grid;
  row-gap: 30px;

  ${minBreakpointQuery.medium`
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  `}
`;

const StyledPaymentInner = styled.div`
  ${minBreakpointQuery.medium`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 25px;
  `}
`;

const StyledSectionHeader = styled(SectionHeader)`
  margin-bottom: 0;
`;

const StyledLabel = styled.label`
  ${fontSize(14)};
  font-weight: ${fontWeights.medium};

  ${({ honeypot }) => {
    if (honeypot) {
      return css`
        ${visuallyHidden()};
      `;
    }
  }}
`;

const StyledRadioLabel = styled.label`
  display: block;
  margin-top: 10px;

  span {
    margin-left: 0;
  }
`;

const StyledInput = styled.input`
  ${fontSize(16)};
  font-family: ${brandFonts.primary};
  font-weight: ${fontWeights.medium};
  width: 100%;
  border: none;
  padding: 15px 0;
  margin: 0;
  margin-top: 10px;

  ${({ alt }) => {
    if (!alt) {
      return css`
        border-bottom: 1px solid ${standardColours.grey};
      `;
    } else {
      return css`
        background-color: ${standardColours.lightGrey};
      `;
    }
  }}
`;

const StyledSelect = styled.select`
  width: 100%;
  ${fontSize(16)};
  padding: 15px 0;
  margin-top: 12px;
  border: none;
  border-bottom: 1px solid ${standardColours.grey};
  cursor: pointer;
  font-family: ${brandFonts.primary};
  font-weight: ${fontWeights.medium};
  opacity: ${({ selected }) => (selected ? 1 : 0.55)};
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
`;

const StyledTextArea = styled.textarea`
  ${fontSize(16)};
  font-family: ${brandFonts.primary};
  font-weight: ${fontWeights.medium};
  width: 100%;
  border: 1px solid ${standardColours.grey};
  padding: 25px;
  min-height: 120px;
  resize: vertical;

  ${minBreakpointQuery.mlarge`
    min-height: 160px;
  `}
`;

const StyledGiftAidWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;

  ${minBreakpointQuery.small`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.medium`
    grid-column: 1 / 3;
  `}

  ${minBreakpointQuery.large`
    margin-top: 25px;
  `}
`;

const StyledGiftAidLogo = styled(Svg)`
  max-height: 25px;
`;

const StyledText = styled.p`
  margin-bottom: 5px;
`;

const StyledSubmit = styled(Button)`
  max-width: 340px;
  margin-top: 20px;

  ${minBreakpointQuery.smedium`
    margin-top: 30px;
  `}

  ${minBreakpointQuery.medium`
    grid-column-start: 1;
   `}

  ${minBreakpointQuery.mlarge`
    margin-top: 40px;
  `}
`;

const StyledPound = styled.span`
  background-color: transparent !important;
  border: none !important;
`;

// moved up for selection
const StyledCheckboxWrapper = styled.label`
  margin: 0;
  display: block;
  padding: 0;
  border: none;

  ${minBreakpointQuery.medium`
    grid-column: 1 / 3;
  `}
`;

const StyledFieldSet = styled.fieldset`
  padding: 0;
  border: none;

  ${StyledCheckboxWrapper} {
    margin-top: 20px;
  }

  ${minBreakpointQuery.medium`
    grid-column: 1 / 3;
  `}
`;

const StyledCheckboxText = styled.span`
  ${checkboxStyles()};
  ${fontSize(16)};
  padding-left: 35px;
  margin: 0;
`;

const StyledCheckbox = styled.input`
  ${visuallyHidden()};

  &:checked + ${StyledCheckboxText} {
    &:before {
      background-color: ${brandColours.primary};
    }

    &:after {
      opacity: 1;
    }
  }
`;

const DonorfyForm = ({ amount = 5, regular }) => {
  const [selected, setSelected] = useState(false);
  const handleSelectionChange = event => {
    if (event.target.value === '') {
      setSelected(false);
    } else {
      setSelected(true);
    }
  };

  const apiKey = process.env.GATSBY_STRIPE_API_KEY;
  const redirectUrl = process.env.GATSBY_STRIPE_REDIRECT_URL;
  const recaptchaKey = process.env.GATSBY_GOOGLE_RECAPTCHA_KEY;
  return (
    <StyledDonorfyForm>
      <link
        type="text/css"
        rel="stylesheet"
        href="https://az763204.vo.msecnd.net/wwcss/stripepayments.2015.1.css"
      />

      {/*<!--Make sure the correct version of the file is uncommented-->*/}
      <script
        type="text/javascript"
        src="https://az763204.vo.msecnd.net/wwjs/stripepayments_2024.6.1.js"
      ></script>
      {/* <script type="text/javascript" src="https://az763204.vo.msecnd.net/wwjs/stripepayments_2024.6.1.demo.js"></script> */}

      <script type="text/javascript" src="https://js.stripe.com/v3/"></script>
      <script
        src={`https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`}
      ></script>

      <StyledInner method="post" id="CreditCardForm">
        <StyledTop>
          <Container narrow={true}>
            <SectionHeader
              overline={'DONATION FORM'}
              heading={'Your details'}
            />
            <StyledTopInner>
              {/* <!-- You can change the layout, mark up, add css classes & styles etc however do not change the field names or field ids --> */}
              <StyledLabel>
                Title*
                <StyledInput
                  type="text"
                  name="Title"
                  id="Title"
                  maxlength="50"
                  placeholder="Title"
                  required
                />
              </StyledLabel>
              <StyledLabel>
                First name*
                <StyledInput
                  type="text"
                  name="FirstName"
                  id="FirstName"
                  maxlength="50"
                  placeholder="First name"
                  required
                />
              </StyledLabel>
              <StyledLabel>
                Last name*
                <StyledInput
                  type="text"
                  name="LastName"
                  id="LastName"
                  maxlength="50"
                  placeholder="Last name"
                  required
                />
              </StyledLabel>
              <StyledLabel>
                Address line 1*
                <StyledInput
                  type="text"
                  name="Address1"
                  id="Address1"
                  maxlength="50"
                  placeholder="Address line 1"
                  required
                />
              </StyledLabel>
              <StyledLabel>
                Address line 2
                <StyledInput
                  type="text"
                  name="Address2"
                  id="Address2"
                  maxlength="50"
                  placeholder="Address line 2"
                />
              </StyledLabel>
              <StyledLabel>
                City*
                <StyledInput
                  type="text"
                  name="Town"
                  id="Town"
                  maxlength="50"
                  placeholder="City"
                  required
                />
              </StyledLabel>
              <StyledLabel>
                County
                <StyledInput
                  type="text"
                  name="County"
                  id="County"
                  maxlength="50"
                  placeholder="County"
                />
              </StyledLabel>
              <StyledLabel>
                Postcode*
                <StyledInput
                  type="text"
                  name="Postcode"
                  id="Postcode"
                  maxlength="10"
                  placeholder="Postcode"
                  required
                />
              </StyledLabel>
              <StyledLabel>
                Select Country
                <StyledSelect
                  name="Country"
                  id="Country"
                  onChange={handleSelectionChange}
                  selected={selected}
                >
                  <option value="">Select Country</option>
                  <option value="Holland">Holland</option>
                  <option value="Germany">Germany</option>
                  <option value="France">France</option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="Italy">Italy</option>
                  <option value="Spain">Spain</option>
                </StyledSelect>
              </StyledLabel>
              <StyledLabel>
                Phone
                <StyledInput
                  type="text"
                  name="Phone"
                  id="Phone"
                  maxlength="50"
                  placeholder="Phone"
                />
              </StyledLabel>
              <StyledLabel>
                Email*
                <StyledInput
                  name="Email"
                  type="email"
                  id="Email"
                  maxlength="50"
                  placeholder="Email"
                  required
                />
              </StyledLabel>
            </StyledTopInner>
          </Container>
        </StyledTop>
        <StyledBottom>
          <Container narrow={true}>
            <StyledPaymentInner>
              <StyledSectionHeader heading={'Payment details'} />
              <PaymentIcons />
            </StyledPaymentInner>
            <StyledBottomInner>
              <StyledLabel text={true} htmlFor="Amount">
                Amount*
                <StyledPound id="AmountPrefix">&pound;</StyledPound>
                <StyledInput
                  alt={true}
                  type="text"
                  name="Amount"
                  className="required numberOnly"
                  id="Amount"
                  maxlength="10"
                  title="Please enter the amount you want to give - don't include the pound sign"
                  value={amount}
                  required
                  readOnly
                />
              </StyledLabel>
              <StyledLabel htmlFor="card-number">
                <StyledText>Card Number*</StyledText>
                <div id="card-number"></div>
              </StyledLabel>
              <StyledLabel htmlFor="card-expiry">
                <StyledText>Expires*</StyledText>
                <div id="card-expiry"></div>
              </StyledLabel>
              <StyledLabel htmlFor="card-cvc">
                <StyledText>Security Code / CVC*</StyledText>
                <div id="card-cvc"></div>
              </StyledLabel>
              <StyledLabel htmlFor="OneOffPayment">
                I would like to make this gift*
                <StyledRadioLabel>
                  <span>A One Off</span>
                  <input
                    type="radio"
                    id="OneOffPayment"
                    name="PaymentType"
                    value="OneOff"
                    checked={regular ? false : true}
                  />
                </StyledRadioLabel>
                <StyledRadioLabel>
                  <span>An Ongoing Commitment to Help</span>
                  <input
                    type="radio"
                    id="RecurringPayment"
                    name="PaymentType"
                    value="Recurring"
                    checked={regular}
                  />
                </StyledRadioLabel>
              </StyledLabel>
              <label htmlFor="Comment">
                <StyledText>What is this donation for?</StyledText>
                <StyledTextArea
                  rows="3"
                  cols="40"
                  name="Comment"
                  id="Comment"
                  title="Optional comments you may wish to make regarding this payment"
                ></StyledTextArea>
              </label>
              <StyledGiftAidWrapper>
                <StyledSectionHeader heading={'Gift Aid'} />
                <StyledGiftAidLogo image={giftAidLogo} />
              </StyledGiftAidWrapper>
              <StyledCheckboxWrapper htmlFor="GiftAid">
                <StyledCheckbox id="GiftAid" type="checkbox" />
                <StyledCheckboxText>
                  Yes I would like you to claim Gift Aid on my donation
                </StyledCheckboxText>
              </StyledCheckboxWrapper>
              <StyledFieldSet>
                <legend>
                  I would like to opt in to receiving an annual update on the
                  charities work and fundraising events
                </legend>
                <StyledCheckboxWrapper>
                  <StyledCheckbox
                    type="checkbox"
                    value="2"
                    className="KeepInTouch"
                  />
                  <StyledCheckboxText>&nbsp;Email</StyledCheckboxText>
                </StyledCheckboxWrapper>
                <StyledCheckboxWrapper>
                  <StyledCheckbox
                    type="checkbox"
                    value="4"
                    className="KeepInTouch"
                  />
                  <StyledCheckboxText>&nbsp;Post</StyledCheckboxText>
                </StyledCheckboxWrapper>
                <StyledCheckboxWrapper>
                  <StyledCheckbox
                    type="checkbox"
                    value="8"
                    className="KeepInTouch"
                  />
                  <StyledCheckboxText>&nbsp;Sms</StyledCheckboxText>
                </StyledCheckboxWrapper>
                <StyledCheckboxWrapper>
                  <StyledCheckbox
                    type="checkbox"
                    value="16"
                    className="KeepInTouch"
                  />
                  <StyledCheckboxText>&nbsp;Phone</StyledCheckboxText>
                </StyledCheckboxWrapper>
              </StyledFieldSet>

              <div id="card-errors" role="alert"></div>
              <div id="ErrorContainer" className="ErrorContainer">
                There is a problem with your donation
                <div id="Errors"></div>
              </div>
              {/* <input type="submit" value="Donate Now" id="submitButton" /> */}
              <StyledSubmit id="submitButton" type="submit">
                Donate Now
              </StyledSubmit>
              <div
                id="PleaseWait"
                style={{ display: 'none', color: 'darkblue' }}
              >
                Processing your donation - please wait ...
              </div>
              {/* <!-- Hidden fields for tags --> */}
              <input type="hidden" id="ActiveTags" value="" />
              <input type="hidden" id="BlockedTags" value="" />
              {/* <!-- Do not change these values --> */}
              <input type="hidden" id="PublishableKey" value={apiKey} />
              <input type="hidden" id="TenantCode" value="N7DHM6NXB5" />
              <input
                type="hidden"
                id="WidgetId"
                value="f841aa8b-dd8a-ee11-a81c-0022489ce8a8"
              />
              <input type="hidden" id="DonationPageId" value="" />
              <input type="hidden" id="RedirectToPage" value={redirectUrl} />
              <input type="hidden" id="ReCaptchaSiteKey" value={recaptchaKey} />
              <input type="hidden" id="ReCaptchaAction" value="Donorfy" />
            </StyledBottomInner>
          </Container>
        </StyledBottom>
      </StyledInner>
      <script>
        {/* // The InitialiseForm() function is called after the form has loaded
    // optionally you add any code you want to run
    // e.g. to style the controls added by Stripe Elements to access the controls
    // use variables cardNumber, cardExpiry and cardCvc
    // see https://stripe.com/docs/stripe-js/reference#element-mount */}
      </script>
    </StyledDonorfyForm>
  );
};

export default DonorfyForm;
